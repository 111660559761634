import { Affix } from 'antd';
import PropTypes from "prop-types";
import React from "react";
import { isMobileOnly } from 'react-device-detect';
//import { InfoFilledIcon } from '../../assets/icons/icons';
import { logEvent } from "firebase/analytics";

function Sidemenu02({ entornoAnalytics, bottom, data, setAccept, analytics}) 
{
  let messageProject = 
  `_*CONSULTA*_

  %0aSaludos, encontré el proyecto *${data.info.name}* 
  %0ade *${data.info.biz}* en www.inmovisor.com/${data.slug}

  %0aAyúdeme con mas información
  `;

  let messageOther = 
  `_*CONSULTA*_

  %0aAyúdeme con mas información
  `;

  const copyLinkClick = () =>
  {
    if (typeof window !== 'undefined') 
    {
      let enlace = window.location.href;
      navigator.clipboard.writeText(enlace);

      let entornoArray = entornoAnalytics.current.clicks;
      entornoArray.push('link');
      entornoAnalytics.current.clicks = entornoArray;
      
      alert("Enlace Copiado!")
    }

    sidemenuNotifications('enlace copiado')
  };

  const sidemenuNotifications = (from) =>
  {    
    let entornoArray = entornoAnalytics.current.clicks;
    entornoArray.push(from);
    entornoAnalytics.current.clicks = entornoArray;    
  }

  return (
    <Affix 
      style = {{ position: "fixed", bottom: bottom + 'vh', right: 10, zIndex : 10000}}
      className = 'select-none'
    >
      <div>
        <button 
          className = "flex items-center justify-center text-white p-3 text-center shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600 mb-2 w-12 h-12 2xl:mb-5 2xl:w-16 2xl:h-16 xl:mb-3 xl:w-13 xl:h-13"
          onClick   = {() => {copyLinkClick(); logEvent(analytics, 'copylink_click', { sidemenu: 'copy_link'})}}
      

        >
          <div
            className = "w-full h-full flex items-center justify-center rounded-2xl hover:bg-sky-600 text-gray-200 hover:text-gray-200 "
          >
            <i className = "fas fa-link flex flex cursor-pointer text-xl 2xl:text-3xl xl:text-2xl"></i>
          </div>
        </button>

        {data.sales.whatsapp && isMobileOnly &&
          <a 
            className = "flex items-center justify-center text-white p-3 text-center shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600 mb-2 w-12 h-12 2xl:mb-5 2xl:w-16 2xl:h-16 xl:mb-3 xl:w-13 xl:h-13"
            target = '_blank'
            rel = "noopener noreferrer"
            href = { `whatsapp://send?phone=${data.sales.whatsapp}&text=${data.visible ? messageProject : messageOther}`}
            onClick   = {() => {sidemenuNotifications('whatsapp'); logEvent(analytics, 'whastapp_click', { sidemenu: 'whatsapp'})}}
          >
            <div
              className = "w-full h-full flex items-center justify-center rounded-2xl hover:bg-sky-600 text-gray-200 hover:text-gray-200 "                       
            >
              <i className = "fab fa-whatsapp flex cursor-pointer text-xl 2xl:text-3xl xl:text-2xl"></i>
            </div>
          </a>
        }

        {data.sales.whatsapp && !isMobileOnly &&
          <a 
            className = "flex items-center justify-center text-white p-3 text-center shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600 mb-2 w-12 h-12 2xl:mb-5 2xl:w-16 2xl:h-16 xl:mb-3 xl:w-13 xl:h-13"
            target = '_blank'
            rel = "noopener noreferrer"
            href = {`https://web.whatsapp.com/send?phone=${data.sales.whatsapp}&text=${data.visible ? messageProject : messageOther}`}
            onClick   = {() => {sidemenuNotifications('whatsapp'); logEvent(analytics, 'whastapp_click', { sidemenu: 'whatsapp'})}}
          >
            <div
              className = "w-full h-full flex items-center justify-center rounded-2xl hover:bg-sky-600 text-gray-200 hover:text-gray-200 "                       
            >
              <i className = "fab fa-whatsapp flex cursor-pointer text-xl 2xl:text-3xl xl:text-2xl"></i>
            </div>
          </a>
        }

        {data.links.webpage &&
          <a 
            className = "flex items-center justify-center text-white p-3 text-center shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600 mb-2 w-12 h-12 2xl:mb-5 2xl:w-16 2xl:h-16 xl:mb-3 xl:w-13 xl:h-13"
            target = '_blank'
            rel = "noopener noreferrer"
            href = {data.links.webpage}
            onClick   = {() => {sidemenuNotifications('webpage'); logEvent(analytics, 'webpage_click', { sidemenu: 'webpage'})}}
          >
            <div
              className = "w-full h-full flex items-center justify-center rounded-2xl hover:bg-sky-600 text-gray-200 hover:text-gray-200 "              
            >
              <i className = "fas fa-globe flex cursor-pointer text-xl 2xl:text-3xl xl:text-2xl"></i>
            </div>
          </a>
        }

        {data.location.waze &&
          <a 
            className = "flex items-center justify-center text-white p-3 text-center shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600 mb-2 w-12 h-12 2xl:mb-5 2xl:w-16 2xl:h-16 xl:mb-3 xl:w-13 xl:h-13"
            target = '_blank'
            rel = "noopener noreferrer"
            href = {`https://www.waze.com/ul?ll=${data.location.coordinates[0]},${data.location.coordinates[1]}&navigate=yes`}
            onClick   = {() => {sidemenuNotifications('waze'); logEvent(analytics, 'waze_click', { sidemenu: 'waze'}) }}
          >
            <div
              className = "w-full h-full flex items-center justify-center rounded-2xl hover:bg-sky-600 text-gray-200 hover:text-gray-200 "             
            >
              <i className = "fab fa-waze flex cursor-pointer text-xl 2xl:text-3xl xl:text-2xl"></i>
            </div>
          </a>
        }

        {data.location.google &&
          <a 
            className = "flex items-center justify-center text-white p-3 text-center shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600 mb-2 w-12 h-12 2xl:mb-5 2xl:w-16 2xl:h-16 xl:mb-3 xl:w-13 xl:h-13"
            target = '_blank'
            rel = "noopener noreferrer"
            href = {`https://www.google.com/maps/dir/?api=1&destination=${data.location.coordinates[0]}%2C${data.location.coordinates[1]}`}
            onClick   = {() => {sidemenuNotifications('google maps'); ; logEvent(analytics, 'gmaps_click', { sidemenu: 'google_maps'})}}
          >
            <div
              className = "w-full h-full flex items-center justify-center rounded-2xl hover:bg-sky-600 text-gray-200 hover:text-gray-200 "              
            >
              <i className = "fas fa-map-marked flex cursor-pointer text-xl 2xl:text-3xl xl:text-2xl"></i>
            </div>
          </a>
        }

        {data.location.gsv &&
          <a 
           className = "flex items-center justify-center text-white p-3 text-center shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600 mb-2 w-12 h-12 2xl:mb-5 2xl:w-16 2xl:h-16 xl:mb-3 xl:w-13 xl:h-13"
            target = '_blank'
            rel = "noopener noreferrer"
            href = {`https://maps.app.goo.gl/${data.location.gsv}`}
            onClick   = {() => {sidemenuNotifications('gsv'); ; logEvent(analytics, 'gsv_click', { sidemenu: 'gsv'})}}
          >
            <div
              className = "w-full h-full flex items-center justify-center rounded-2xl hover:bg-sky-600 text-gray-200 hover:text-gray-200 "
            >
              <i className = "fas fa-street-view flex cursor-pointer text-xl 2xl:text-3xl xl:text-2xl"></i>
            </div>
          </a>
        }

        <button 
          className = "flex items-center justify-center text-white p-3 text-center shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600 mb-2 w-12 h-12 2xl:mb-5 2xl:w-16 2xl:h-16 xl:mb-3 xl:w-13 xl:h-13"
          onClick   = {() => setAccept(false)}
        >
          <div
            className = "w-full h-full flex items-center justify-center rounded-2xl hover:bg-sky-600 text-gray-200 hover:text-gray-200 "                       
          >
            <i className = "fas fa-info flex cursor-pointer text-xl 2xl:text-3xl xl:text-2xl"></i>
          </div>
        </button>

        {/* 
        <div 
          className = "flex items-center justify-center text-white p-3 text-center mb-2 md:mb-4 lg:mb-5 lg:w-16 lg:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
          //onClick   = {() => clickInfo()}
        >
          <InfoFilledIcon className = ' text-xl lg:text-2xl flex cursor-pointer '/>
        </div>
        */}
      </div>
    </Affix>
  )
}

Sidemenu02.propTypes = {
  siteTitle: PropTypes.string,
}

Sidemenu02.defaultProps = {
  siteTitle: ``,
}

export default Sidemenu02
