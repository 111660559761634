//Dependecies
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Spin } from 'antd';
import 'aframe';
import 'aframe-look-at-component';
import { Entity } from 'aframe-react';
import '../../scripts/e-look-controls';
import { AppstoreOutlined } from '@ant-design/icons';

/* 360 VIEWER */
const Virtual01 = ({ entornoAnalytics, areaSelected, isMobile, tourList, initialAngle, galleryDisabled, setGalleryDisabled, setAccept, accept, data, buttonList, ambientModal, setAmbientModal }) =>
{
  /* SETTINGS */
  const waitingTime = 3; //In seconds
  let startTime = (new Date()).getTime();
  const [from, setFrom] = useState('01');
  const [fov, setFov] = useState(null);
  const [viewangle, setViewangle] = useState(initialAngle);
  const [contador, setContador] = useState(waitingTime);
  const [transition, setTransition] = useState(null);
  const [downTime, setDownTime] = useState(null);  

  /* CARGAR EL PRIMER PANORAMA */
  const [firstPanoramaPreview, setFirstPanoramaPreview] = useState(null);
  const [firstPanoramaImage, setFirstPanoramaImage] = useState(null);
  const [previewFinish, setPreviewFinish] = useState(false);
  const [firstPanoFinish, setFirstPanoFinish] = useState(false);

  /* CARGAR TOURS */
  const [firstTourList, setFirstTourArray] = useState(null);
  const [alltToursList, setAllToursArray] = useState(null);
  const [firstTourLoaded, setFirstTourLoaded] = useState(false);
  const [allToursLoaded, setAllToursLoaded] = useState(false);
  const [hotspotsList, setHotspotsList] = useState(null);

  const panoramaRef = useRef(null);
  const [isInteracting, setIsInteracting] = useState(false);
  const [interacted, setInteracted] = useState(false);

  let firstTourPanoCounter = 0;
  let ToursPanoCounter = 0;

  /* TOURING */
  const [panoID, setPanoID] = useState(`${areaSelected}_pano01`);

  /* FUNCIONES */
  const hotspotClick = useCallback((e, data) => 
  {
    let upTime = Math.round(e.timeStamp);    

    if(upTime - downTime > 40 && upTime - downTime < 400)
    {
      setTransition(null);
      setFrom(data.to);
      setPanoID(`${areaSelected}_pano${data.to}`);
      setViewangle(data.cameraRotation);  
    }

  }, [areaSelected, downTime]); 

  const continueNavigating = () =>
  {
    setAccept(true);

    setContador(0);

    if(allToursLoaded)
    {
      setAmbientModal(true);
    }
  }

  const onFirstTourLoad = useCallback((counterMax, startTime) => 
  {   
    let timeDelta = (new Date()).getTime() - startTime;   

    firstTourPanoCounter = firstTourPanoCounter + 1

    if(counterMax === firstTourPanoCounter)
    {      
      entornoAnalytics.current.firstTourTime = timeDelta/1000;      

      setFirstTourLoaded(true);
    }  
    
  }, [firstTourPanoCounter]); 

  const onAllToursLoad = useCallback((counterMax, startTime) => 
  {   
    let timeDelta = (new Date()).getTime() - startTime;   

    ToursPanoCounter = ToursPanoCounter + 1

    if(counterMax === ToursPanoCounter)
    {      
      entornoAnalytics.current.allToursTime = timeDelta/1000;      

      setAllToursLoaded(true);
      setGalleryDisabled(false);

      if(contador === 0)
      {
        setAmbientModal(true);
      }      
    }  
    
  }, [setGalleryDisabled, ToursPanoCounter]);  

  const onDown = (time) =>
  {
    setDownTime(time);
  }

  /* FIRST PANORAMA */
  useEffect(() =>
  { 
    let tourSelected = tourList.find(tour => tour.area === areaSelected);

    setFirstPanoramaPreview(tourSelected.placeholder)
    setFirstPanoramaImage(tourSelected.panoramas[0].url)
  }, []);  

  /* RESET TOUR */
  useEffect(() =>
  {
    setTransition(null);
    setFrom('01');
    setPanoID(`${areaSelected}_pano01`);
    
    setViewangle('0');
  }, [areaSelected]);

  /* INIT ASPECT RATIO */
  useEffect(() =>
    { 
      if(isMobile)
      {
        setFov(120);
      }
      else
      {
        setFov(95);
      }
    }, [isMobile]);

   /* CONTADOR */
  useEffect(() => 
  {
    let timer;

    if(firstPanoFinish)
      {
        timer = setTimeout(() => {
          if (contador > 0) {
            setContador(contador - 1);
          }
        }, 1000);
      } 

    if(firstPanoFinish && contador === 0)
    {
      continueNavigating();
    }

    return () => clearTimeout(timer);
  }, [contador, firstPanoFinish]);

  /* FIRST TOUR */
  useEffect(() =>
    {
      let allPanos = [];
  
      let firstTour = tourList.find(tour => tour.area === areaSelected);
  
      firstTour.panoramas.forEach((pano) => 
      {
        allPanos.push({area:firstTour.area, ...pano});
      });
  
      let allPanoramas = allPanos.map((panorama, index) => 
      {        
        return(
          <a-assets
            key     = {index}
            timeout = "1000"
          >
            <img 
              alt         = 'true' 
              id          = {`${panorama.area}_${panorama.id}`}
              crossOrigin = 'anonymous'
              src         = {panorama.url}
              onLoad      = {() => onFirstTourLoad(allPanos.length, startTime)}
            />
          </a-assets>
        );
      });
      
      setFirstTourArray(allPanoramas);
  
  }, [setFirstTourArray]);

  /* REST OF TOURS */
  useEffect(() =>
    {
      if(tourList.length > 1)
      {
        let allPanos = [];
  
        tourList.forEach(area => 
        {
          if(area.area !== areaSelected)
          {
            area.panoramas.forEach((pano) => 
            {          
              allPanos.push({area:area.area, ...pano});
            });
          }       
        });
  
        let allPanoramas = allPanos.map((panorama, index) => 
        {
          return(
            <a-assets
              key     = {index}
              timeout = "1000"
            >
              <img 
                alt         = 'true' 
                id          = {`${panorama.area}_${panorama.id}`}
                crossOrigin = 'anonymous'
                src         = {panorama.url}
                onLoad      = {() => onAllToursLoad(allPanos.length, startTime)}
              />
            </a-assets>
          );
        });
        
        setAllToursArray(allPanoramas);
      }
      
  }, [setAllToursArray]);

  /* AREA HOTSPOTS */
  useEffect(() =>
  {
    const arrowCoords =
      {
        coord01: 
        {
          position  : '-0.5657 0 0',
          rotation  : '0 0 -45',
          color     : '#44D62C',
          height    : 2,
          width     : 0.4,
          opacity   : 1,
        },

        coord02: 
        {
          position  : '0.5657 0 0',
          rotation  : '0 0 45',
          color     : '#44D62C',
          height    : 2,
          width     : 0.4,
          opacity   : 1,
        },

        coord03: 
        {
          position  : '-0.5657 0.9534 0',
          rotation  : '0 0 -45',
          color     : '#44D62C',
          height    : 2,
          width     : 0.4,
          opacity   : 1,
        },

        coord04: 
        {
          position  : '0.5657 0.9534 0',
          rotation  : '0 0 45',
          color     : '#44D62C',
          height    : 2,
          width     : 0.4,
          opacity   : 1,
        },

        coord05: 
        {
          position  : '-0.5657 -1 0',
          rotation  : '0 0 -45',
          color     : '#44D62C',
          height    : 2,
          width     : 0.4,
          opacity   : 1,
        },

        coord06: 
        {
          position  : '0.5657 -1 0',
          rotation  : '0 0 45',
          color     : '#44D62C',
          height    : 2,
          width     : 0.4,
          opacity   : 1,
        },
      }

    const arrowShadowCoords =
    {      
      coord07: 
      {
        position  : '-0.5657 0 -.01',
        rotation  : '0 0 -45',
        color     : '#171717',
        height    : 2.1,
        width     : 0.5,
        opacity   : 0.8,
      },

      coord08: 
      {
        position  : '0.5657 0 -.01',
        rotation  : '0 0 45',
        color     : '#171717',
        height    : 2.1,
        width     : 0.5,
        opacity   : 0.8,
      },

      coord09: 
      {
        position  : '-0.5657 0.9534 -.01',
        rotation  : '0 0 -45',
        color     : '#171717',
        height    : 2.1,
        width     : 0.5,
        opacity   : 0.8,
      },

      coord10: 
      {
        position  : '0.5657 0.9534 -.01',
        rotation  : '0 0 45',
        color     : '#171717',
        height    : 2.1,
        width     : 0.5,
        opacity   : 0.8,
      },

      coord11: 
      {
        position  : '-0.5657 -1 -.01',
        rotation  : '0 0 -45',
        color     : '#171717',
        height    : 2.1,
        width     : 0.5,
        opacity   : 0.8,
      },

      coord12: 
      {
        position  : '0.5657 -1 -.01',
        rotation  : '0 0 45',
        color     : '#171717',
        height    : 2.1,
        width     : 0.5,
        opacity   : 0.8,        
      },
    }
    
    let arrow = Object.keys(arrowCoords).map((m1, i) => 
    {
      let data = {...arrowCoords[m1]};
      
      return(
        <Entity
          key         = {i}
          //id          = 'box'
          visible     = 'true'
          geometry    = {{ 
                          primitive   : "plane",
                          height      : data.height,
                          width       : data.width,
                        }}
          position    = {data.position}
          rotation    = {data.rotation}

          material    = {`shader: flat; color: ${data.color}; opacity: ${data.opacity}`}
        />
      )
    });

    let arrowShadow = Object.keys(arrowShadowCoords).map((m1, i) => 
    {
      let data = {...arrowShadowCoords[m1]};
      
      return(
        <Entity
          key         = {i}
          //id          = 'box'
          visible     = 'true'
          geometry    = {{ 
                          primitive   : "plane",
                          height      : data.height,
                          width       : data.width,
                        }}
          position    = {data.position}
          rotation    = {data.rotation}

          material    = {`shader: flat; color: ${data.color}; opacity: ${data.opacity}`}
        />
      )
    });

    let tourSelected = tourList.find((tour) => tour.area === areaSelected);

    let tourHotspots = tourSelected.hotspots.map((hotspot, index) => 
    {
      if(hotspot.from === from)
      {
        return(
          <Entity
            key         = {index}
            //id          = 'box'
            visible     = 'true'
            geometry    = {{ 
                            primitive   : "plane",
                            height      : 3,
                            width       : 3,
                          }}
            position    = {hotspot.hotspotPosition}
            rotation    = {`0 0 0`}

            material    = {`shader: flat; transparent: true; opacity: 0`}

            look-at     = "[camera]"
          >
            <Entity
              id          = 'box'
              visible     = 'true'
              geometry    = {{ 
                              primitive   : "plane",
                              height      : 4,
                              width       : 3,
                            }}
              position    = {'0 -1.5 0'}
              rotation    = {`-60 0 0`}

              events      =
              {{
                click : (e) => hotspotClick(e, hotspot),
              }}

              material    = {`shader: flat; transparent: true; opacity: 0`}
            >
              {arrowShadow}
              {arrow}              
            </Entity>          
          </Entity>
        );
      }
      else
      {
        return null;
      }
    });

    setHotspotsList(tourHotspots);
  }, [areaSelected, from, panoID, hotspotClick, tourList]);  

  /* TRANSITION SPHERE */
  useEffect(() =>
  { 
    let sphere = (
      <Entity
        visible     = 'true'
        geometry    = {{ 
                        primitive   : "sphere",
                        radius      : '3'
                      }}
        position    = {`0 0 0`}
        rotation    = {`0 0 0`}
        scale       = {`1 1 1`}
        material    = {"color: black; side: back; shader: flat"}
        animation   = {"property: material.opacity; from: 1; to: 0; dur: 1500; easing: linear; loop: false; "}
      />
    );

    setTransition(sphere);
  }, [areaSelected]);

  useEffect(() => 
  {
    if(interacted) return;
    const handleMouseDown = () => setIsInteracting(true);
    const handleMouseUp = () => setIsInteracting(false);
    const handleTouchStart = () => setIsInteracting(true);
    const handleTouchEnd = () => setIsInteracting(false);
    const handleMouseMove = () => 
    {
      
      if (isInteracting) 
      {
        // Aquí puedes manejar el movimiento del panorama
        console.log('Moviendo panorama con el mouse');
        setInteracted(true);
        entornoAnalytics.current.interacted = true;
      }
    };

    const handleTouchMove = () => 
    {
      if (isInteracting) 
      {
        // Aquí puedes manejar el movimiento del panorama
        console.log('Moviendo panorama con el toque');
        setInteracted(true);
        entornoAnalytics.current.interacted = true;   
      }
    };

    const panorama = panoramaRef.current;

    panorama.addEventListener('mousedown', handleMouseDown);
    panorama.addEventListener('mouseup', handleMouseUp);
    panorama.addEventListener('mousemove', handleMouseMove);
    panorama.addEventListener('touchstart', handleTouchStart);
    panorama.addEventListener('touchend', handleTouchEnd);
    panorama.addEventListener('touchmove', handleTouchMove);

    return () => {
      panorama.removeEventListener('mousedown', handleMouseDown);
      panorama.removeEventListener('mouseup', handleMouseUp);
      panorama.removeEventListener('mousemove', handleMouseMove);
      panorama.removeEventListener('touchstart', handleTouchStart);
      panorama.removeEventListener('touchend', handleTouchEnd);
      panorama.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isInteracting]);

  if (typeof window !== 'undefined') 
  {
    return(
      <div 
        style = 
          {{
            height : '100vh',
            width : '100vw',
          }}
        
        onMouseDown = {(e) => onDown(Math.round(e.timeStamp))}   
      >
        {!accept &&
          <div
            className = {`fixed absolute flex justify-center items-center flex-col z-[20000] bg-gray-900 select-none transition-all duration-1000	ease-in-out px-4 ${previewFinish || firstPanoFinish ? "bg-opacity-80" : "bg-opacity-100"}`}
            style = 
            {{
              height : '100vh',
              width : '100vw',
            }}
          >
            {!firstPanoFinish && !allToursLoaded &&
              <span
                className = 'flex justify-center mt-4 lg:text-2xl text-xl text-white font-semibold drop-shadow-xl'
              >
                <Spin size = "large"/>
                &nbsp;
                <p>CARGANDO 1era IMAGEN</p>
                &nbsp;
                <Spin size = "large"/>
              </span>
            }

            {firstPanoFinish && !allToursLoaded && !galleryDisabled &&
              <span
                className = 'flex justify-center mt-4 lg:text-2xl text-xl text-white font-semibold drop-shadow-xl'
              >
                <Spin size = "large"/>
                &nbsp;
                <p>CARGANDO AMBIENTES</p>
                &nbsp;
                <Spin size = "large"/>
              </span>
            }

            {firstPanoFinish && !allToursLoaded && galleryDisabled &&
             <span
                className = 'flex flex-row justify-center mt-4 lg:text-2xl text-base text-white font-semibold drop-shadow-xl items-center text-center	'
              >
                <p className = 'uppercase lg:text-2xl text-xl my-auto'>BIENVENIDOS A {data.info.name} by {data.info.biz}</p>                
              </span>
            }

            {allToursLoaded && 
              <span
                className = 'flex flex-row justify-center mt-4 lg:text-2xl text-base text-white font-semibold drop-shadow-xl items-center text-center	'
              >
                <p className = 'uppercase lg:text-2xl text-xl my-auto'>BIENVENIDOS A {data.info.name} by {data.info.biz}</p>                
              </span>
            }

            <ul className="space-y-4 mt-4">

              {isMobile &&
                <li className = "flex items-center drop-shadow-xl">
                  <i className = "fas fa-mobile text-2xl xl:text-4xl text-white"></i>

                  <p className = "ml-4 mb-0">
                    <span className = "text-base md:text-xl lg:text-xl font-extrabold font-mono text-white">PRESIONA PANTALLA PARA NAVEGAR</span> 
                  </p>
                </li>
              }

              {!isMobile &&
                <li className = "flex items-center drop-shadow">
                  <i className = "fas fa-mouse text-2xl xl:text-4xl text-white"></i>

                  <p className = "ml-4 mb-0">
                    <span className = "text-base md:text-xl lg:text-xl font-extrabold font-mono text-white">PRESIONA BOTÓN IZQUIERDO MOUSE PARA NAVEGAR</span> 
                  </p>
                </li>
              }
  
              <li className = "flex items-center drop-shadow">
                <i className = "fas fa-angle-double-up text-2xl xl:text-4xl text-white"></i>

                <p className = "ml-4 mb-0">
                  <span className = "text-base md:text-xl lg:text-xl font-extrabold font-mono text-white">PRESIONA LAS FLECHAS PARA AVANZAR</span> 
                </p>
              </li>
              
              <li className = "flex items-center text-white drop-shadow">
                <AppstoreOutlined className = "fas fa-mobile text-2xl xl:text-4xl text-white"/>

                <p className = "ml-4 mb-0">
                  <span className = "text-base md:text-xl lg:text-xl font-extrabold font-mono text-white">MENÚ DE AMBIENTES</span> 
                </p>
              </li>

              <li className = "flex items-center drop-shadow">
                <i className = "fab fa-whatsapp text-2xl xl:text-4xl text-green-300"></i>

                <p className = "ml-4 mb-0">
                  <span className = "text-base md:text-xl lg:text-xl font-extrabold font-mono text-white">PARA COMUNICARTE POR </span> 
                  <span className = "text-base md:text-xl lg:text-xl font-extrabold font-mono text-green-300">WHATSAPP</span> 
                </p>
              </li>              

              <li className = "flex items-center">
                <p className = "mb-0">
                  <span className = "text-base md:text-xl lg:text-xl font-extrabold font-mono text-white">MAS INFORMACIÓN - MENÚ DERECHO</span> 
                </p>

                &nbsp;
                &nbsp;
                &nbsp;
                
                <i className = "fas fa-arrow-right text-2xl xl:text-4xl text-white"></i>
              </li>
            </ul>

            {!firstPanoFinish &&
              <div className = "pt-4 text-base font-semibold flex justify-center">
                 <button 
                  className = "bg-gray-600 text-white font-semibold py-2 px-4 border-2 border-gray-800 rounded"
                  disabled
                >
                  CARGANDO TOURS
                </button>
              </div>
            }

            {firstPanoFinish && contador === 0 &&
              <div className = "pt-4 text-base font-semibold flex justify-center">
                <button 
                  className = "bg-transparent bg-sky-500 hover:bg-sky-900 text-white font-semibold hover:text-white py-2 px-4 border-2 border-white rounded"
                  onClick   = {() => continueNavigating()}  
                >                  
                  CONTINUAR
                </button>
              </div>
            }

            {firstPanoFinish && contador > 0 &&
              <div className = "pt-4 text-base font-semibold flex justify-center">
                <button 
                  className = "bg-transparent bg-sky-500 hover:bg-sky-900 text-white font-semibold hover:text-white py-2 px-4 border-2 border-white rounded"
                  onClick   = {() => continueNavigating()}  
                >                  
                  CONTINUA EN {contador}s 
                </button>
              </div>
            }

          </div>
        }

        {ambientModal &&
          <div
            className = 'fixed absolute flex justify-center items-center flex-col z-[20000] bg-gray-900 bg-opacity-80 select-none'
            style = 
            {{
              height : '100vh',
              width : '100vw',
            }}
          >
            <div className = "mx-auto px-[12px] select-none ">
              <p className = "flex justify-center text-base md:text-xl lg:text-2xl text-white font-semibold font-mono uppercase">{`ambientes - ${data.info.name}`}</p>
            </div>
     
            <div className = "w-full justify-center flex my-2 max-h-[70vh] overflow-auto">
              <div className = "px-5 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                {buttonList}
              </div>
            </div>            

            <div className = "pt-4 text-base font-semibold flex justify-center">
              <button 
                className = "bg-transparent bg-sky-500 hover:bg-sky-900 text-white font-semibold hover:text-white py-2 px-4 border-2 border-white rounded"
                onClick   = {() => setAmbientModal(false)}  
              >
                CONTINUAR
              </button>
            </div>
          </div>
        }
       
        <a-scene 
          device-orientation-permission-ui = "enabled: false" 
          disable-inspector 
          touchEnabled 
          vr-mode-ui = "enabled:false;" 
          id = "myEmbeddedScene" 
          embedded
          raycaster = "objects: .raycastable"
          loading-screen = "dotsColor: white; backgroundColor: black; enabled:false" 
          ref = {panoramaRef}
        >         
          <a-assets
            timeout = "1000"
          >
            <img 
              alt         = 'true' 
              id          = {'blurredpano'}
              crossOrigin = 'anonymous'
              src         = {firstPanoramaPreview}
              onLoad      = {() => {setPreviewFinish(true); /*console.log("se cargó el preview pano")*/}}
            />
          </a-assets>

          <a-assets
            timeout = "1000"
          >
            <img 
              alt         = 'true' 
              id          = {'firstPano'}
              crossOrigin = 'anonymous'
              src         = {firstPanoramaImage}
              onLoad      = {() => {setFirstPanoFinish(true); /*console.log("se cargó el primer panorama")*/}}
            />
          </a-assets>         
          
          <Entity
            primitive = "a-sky" 
            height = "1024"
            width = "1024"
            src={`#${firstTourLoaded ? (!firstTourLoaded ? 'firstpano' : panoID) : (firstPanoFinish ? 'firstPano' : 'blurredpano')}`}
          />

                          
          {firstTourList}
          {alltToursList}
          {transition}
          {firstTourLoaded && hotspotsList}

          <a-camera
            fov             = {!fov ? '' : fov }
            zoom            = {1}
            position        = "0 1.6 0" 
            wasd-controls   = 'enabled: false' 
            look-controls   = 'enabled: false'

            e-look-controls = {`enabled: true; customRot : ${viewangle}; customPitch:  ${areaSelected === 'aereo' ? -1.192 : 0}; autorotateSpeed: ${isMobile ? '0.001' : '0.0003'};` }              
          >
            <a-entity
              cursor = "rayOrigin: mouse; fuseTimeout: 0;"
              raycaster = "objects: #box"
            />
          </a-camera>    
        </a-scene>
       
      </div>
    )
  }
  else
  {
    return null;
  }
}

export default Virtual01;