import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function SEO({ lang, meta, metadata, title, slug, biz }) 
{ 
  const defaultTitle = metadata.title;

  return (
    <Helmet
      htmlAttributes =
      {{
        lang,
      }}
           
      title = {title}
      titleTemplate = {defaultTitle ? `%s | ${defaultTitle} by ${biz}` : null}
      
      meta = {[
        {
          name: `description`,
          content: metadata.description,
        },
        {
          name: `keywords`,
          content: metadata.keywords,
        },
        {
          property: `og:title`,
          content: `${title} | ${defaultTitle} by ${biz}`,
        },
        {
          property: `og:description`,
          content: metadata.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metadata.image,
        },
        {
          property: `og:image:width`,
          content: "1000",
        },
        {
          property: `og:image:height`,
          content: "500",
        },
        {
          property: `og:image:type`,
          content: "image/jpg",
        },
        {
          property: `og:url`,
          content: `https://inmovisor.com/${slug}`,
        },        
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${defaultTitle}`,
        },
        {
          name: `twitter:description`,
          content: metadata.description,
        },
        {
          name: `twitter:image`,
          content: metadata.image,
        },
      ].concat(meta)}      
    >
      <link rel="canonical" href={`https://inmovisor.com/${slug}`}/>
      
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/antd/4.23.2/antd.compact.min.css" integrity="sha512-GBIEqbWXLrBKOaiLmd5X19IwJ7a62bAsnuxyepRaPAEUDZXWWgIQNKbVK14FoMKDPd4TJAx7t1BDEfGtd+U6fw==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    
      <link rel = "stylesheet" href = "https://demos.creative-tim.com/notus-js/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css"></link>

      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap" rel="stylesheet"/>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
