import React, { useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby";
import { isMobileOnly } from 'react-device-detect';

import Layout from "../components/layouts/layout01";
import Seo from "../components/seos/seo01";

import Thumbnail01 from '../components/thumbnail/Thumbnail01';
import Virtual from '../components/virtual/Virtual01';

import withLocation from '../components/hocs/withlocation';

import { getAnalytics } from 'firebase/analytics';
import app from 'gatsby-plugin-firebase-v9.0';

let analytics = 'undefined';

const ProjectDetails = ({ data, search  }) => 
{
  const { id } = search;
  const { meta } = data.projectsJson;
  
  const [buttonList, setButtonList] = useState(true);
  const [tourList, setTourList] = useState(null);

  const [galleryDisabled, setGalleryDisabled] = useState(true);
  const [deviceDetected, setDeviceDetected] = useState(false);

  const [isEmbedded, SetIsEmbedded] = useState(false);

  const [accept, setAccept] = useState(false);  

  const [ambientModal, setAmbientModal] = useState(false);

  const mainArea = data.projectsJson.tours.mainTour;
  const areas = data.projectsJson.tours.areas;
  const tours = data.allToursJson.nodes;

  const entornoAnalytics = useRef(
  {
    startTime: null,
    endTime: null,
    project: null,
    device: null,
    firstTourTime: null,
    allToursTime: null,
    embedded: null,
    ambients: [id ? id : mainArea],
    interacted: false,
    clicks: []
  });

  const { TelegramClient } = require('messaging-api-telegram');
  const userID = '1196253376'; //esto se ve en @userinfobot dentro del chat d instagram. es mi # de usuario.
  const client = new TelegramClient({
    accessToken: '2099891144:AAGVK_5nJ3nUX0mwb-eUMFc-gWlJAsPuYIA', //este API se le ve en el chat, con el bot @BotFather
    onRequest: ({ method, url, headers, body }) => 
    {
      /* */
    },
  });
  
  /* ANALYTICS */
  useEffect(() => {
    if (typeof window !== 'undefined') {
      analytics = getAnalytics(app);
    }
  }, []);

  /* DEVICE DETECT */
  useEffect(() => 
  {
    if (isMobileOnly) 
    {
      setDeviceDetected(true);
      entornoAnalytics.current.device = 'mobile'
    }
    else
    {
      entornoAnalytics.current.device = 'PC'
    }

    entornoAnalytics.current.startTime = new Date().getTime();   
  }, []);  

  /* EMBEDDED CHECK */
  useEffect(() => 
  {
    if (typeof window !== 'undefined') 
    {
      if (window.top !== window.self) 
      {
        // La página está dentro de un iframe
        SetIsEmbedded(true);
        entornoAnalytics.current.embedded = true;
      } 
      else 
      {
        // La página NO está carga dentro de un iframe
        SetIsEmbedded(false);
        entornoAnalytics.current.embedded = false;
      }
     }    
  }, []); 

  /* BOTONES Y TOURS */
  useEffect(() => 
  { 
    entornoAnalytics.current.project = data.projectsJson.slug;

    let areaSelected  = id ? id : mainArea;

    let propertyTours = [];
    let panoramasLength = 0;

    let list = areas.map((area, index) => 
    {
      let tourSelected = tours.find((tour) => tour.slug === area.tour);

      propertyTours.push({...tourSelected});

      panoramasLength = panoramasLength + tourSelected.panoramas.length;  

      if(window === 'undefined' && analytics === 'undefined') return null;

      return(
        <Thumbnail01 
          key     = {index}
          width   = {(70/100) * window.innerHeight/4}
          image   = {tourSelected.preview.image}
          alt     = {tourSelected.preview.alt}

          title   = {area.title}

          slug    = {data.projectsJson.slug}
          url     = {mainArea === area.name ? `/${data.projectsJson.slug}` : `/${data.projectsJson.slug}?id=${area.name}`}

          selected  = {tourSelected.area === areaSelected ? true : false}
          
          analytics     = {analytics}

          entornoAnalytics = {entornoAnalytics}

          area    = {area.name}
        />)
    });

    setTourList(propertyTours);
    setButtonList(list);
  }, [id, areas, data.projectsJson.slug, mainArea, tours]);

  /* CIERRA LA VENTANA */
  useEffect(() => 
  {
    const handleVisibilityChange = () => 
    {
      if (document.visibilityState === 'hidden') 
      {       
        entornoAnalytics.current.endTime = new Date().getTime();
      
        let totalTime = ((entornoAnalytics.current.endTime - entornoAnalytics.current.startTime)/1000).toFixed(0);

        let minutes = Math.floor(totalTime / 60);
        let remainingSeconds = totalTime % 60;

        let timeInPage = `${minutes > 0 ? `${minutes}m ` : ''}${remainingSeconds}s`

        let message = `Proyecto: ${entornoAnalytics.current.project}\nTipo de dispositivo: ${entornoAnalytics.current.device}\nEstá en otra página: ${entornoAnalytics.current.embedded}\nSe demoró ${entornoAnalytics.current.firstTourTime} segundos en cargar el primer tour\nSe demoró ${entornoAnalytics.current.allToursTime} segundos en cargar resto de tours\nSe visitaron las siguientes áreas: ${entornoAnalytics.current.ambients}\nSe dió click a los siguientes botones: ${entornoAnalytics.current.clicks}\nPasó ${timeInPage} dentro de la página.\nInteractuó con el 360: ${entornoAnalytics.current.interacted}`;
        
        console.log(message);

        try 
        {
          client.sendMessage(userID, message).then(() => 
          {
            console.log('sent');
          }); 
        } 
        catch (error) 
        {
          console.log(error);
        }

        // Realiza cualquier acción necesaria cuando la página se oculta
      } 
      else 
      {
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Limpia el evento cuando el componente se desmonte
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [client]);  

  return(
    <Layout
      navHeight     = {5}
      menuHeight    = {7}
      data          = {data.projectsJson}
      isMobile      = {deviceDetected}

      buttonList    = {buttonList}

      tourList      = {tourList}
      areaSelected  = {id ? id : mainArea}

      galleryDisabled = {galleryDisabled}

      isEmbedded      = {isEmbedded}

      setAccept       = {setAccept}
      setAmbientModal = {setAmbientModal}

      analytics     = {analytics}

      entornoAnalytics = {entornoAnalytics}
    >
      <Seo 
        metadata = {meta}
        title = "Inmovisor"
        slug  = {data.projectsJson.slug}
        biz  = {data.projectsJson.info.biz.toUpperCase()}
      />     

      {typeof window !== 'undefined' && tourList &&
        <Virtual          
          isMobile  = {deviceDetected}

          tourList  = {tourList}

          areaSelected = {id ? id : mainArea}
          mainArea = {mainArea}
          initialAngle = {data.projectsJson.tours.initialAngle ? data.projectsJson.tours.initialAngle : "0"}

          galleryDisabled = {galleryDisabled}
          setGalleryDisabled = {setGalleryDisabled}

          setAccept = {setAccept}
          accept = {accept}

          data      = {data.projectsJson}

          buttonList    = {buttonList}

          ambientModal  = {ambientModal}
          setAmbientModal = {setAmbientModal}
          
          entornoAnalytics = {entornoAnalytics}
        />
      }
    </Layout>
  )
}

export default withLocation(ProjectDetails)

export const query = graphql`
  query ProjectQuery($slug: String) {
    allToursJson {
    nodes {
      slug
      area
      data{
        rooms
        baths
        parking
        area
        price
      }
      preview {
        alt
        image{
        childrenImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
            webpOptions: {quality: 20}
            jpgOptions: {progressive: true, quality: 60}
            blurredOptions: {width: 10}
            formats: WEBP
            aspectRatio: 1
            width: 400
            breakpoints: [320,480,640, 1024,1920]
          )
        }
      }
      }
      credits {
        creator
        slug
      }
      placeholder
      panoramas {
        id
        pano
        url
      }
      hotspots {
        from
        to
        hotspotPosition
        cameraRotation
      }
    }
  }
  
   projectsJson(slug: {eq: $slug}) {
      slug
      visible      
      preview {
        image
      }
      sales {
        whatsapp
      }
      location {
        google
        waze
        gsv
        coordinates
      }
      links {
        webpage
      }
      info {
        name
        biz
        bizUserName
        description
        qr
        available
      }
      meta {
        title
        description
        image
      }
      tours {
        mainTour
        initialAngle
        areas {
          name
          tour
          title
        }
      }
    }
  }
`