import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"

import Menu02 from "../menus/Menu02";
import Navbar01 from "../navbars/Navbar01";
import Navbar02 from "../navbars/Navbar02";
import Sidemenu02 from "../sidemenus/Sidemenu02";

const Layout = ({ entornoAnalytics, isEmbedded, children, galleryDisabled, menuHeight, navHeight, clickLayer, clickInfo, data, isMobile, tourList, areaSelected, setAccept, setAmbientModal, analytics }) => 
{
  const [info, setData] = useState(null);

  /* PANORAMA */
  useEffect(() =>
  {    
    if(tourList)
    {      
      let tourSelected = tourList.find((tour) => tour.area === areaSelected);
      setData(tourSelected.data);     
    }
  }, [areaSelected, tourList]);

  return (
    <>
      <div
        style = 
        {{
          margin: `0`,
        }}
      >
        <Navbar01
          data          = { data }
          navHeight     = { navHeight }
          info          = { info }
        />

        {info &&
          <Navbar02
            height        = { navHeight }
            info          = { info }
            data          = { data }
          />
        }

        <main>{children}</main>

        {tourList &&
          <Menu02
            menuHeight      = {menuHeight}

            setAmbientModal = {setAmbientModal}

            galleryDisabled = {galleryDisabled}
            
            tourList        = {tourList}

            isEmbedded    = {isEmbedded}

            entornoAnalytics = {entornoAnalytics}
          />
        }      

        <Sidemenu02
          bottom        = {menuHeight}
          clickLayer    = {clickLayer}
          clickInfo     = {clickInfo}

          isMobile      = {isMobile}

          data          = { data }

          setAccept     = {setAccept}

          analytics     = {analytics}

          entornoAnalytics = {entornoAnalytics}
        />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
