//Dependencies
import React from 'react';
import { Link } from "gatsby";
import { logEvent } from "firebase/analytics";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

//https://firebase.google.com/docs/analytics/events?platform=web

/* BOTONES DE AMBIENTES */

//text-xs md:text-xs text-base
const Thumbnail01 = (props) =>
{
  const { entornoAnalytics, alt, image, width, title, url, selected, slug, analytics, area } = props;

  const ambientClick = (slug, area) =>
  {
    logEvent(analytics, 'ambient_click', { content_type: slug, content_id: title});
    let entornoArray = entornoAnalytics.current.ambients;
    entornoArray.push(area);

    entornoAnalytics.current.ambients = entornoArray;
  }

  return(
    <Link
      className = {"border border-solid border-black cursor-pointer"}
      to          = {url}

      style     = {{width : width, height : width , border: selected ? '4px solid #00ff40' : null}}
      //onClick   = {() => console.log(slug, title)}
      //onClick   = {() => {logEvent(analytics, 'button_click', {content_type: 'click', content_id: `${slug}_${title}`});} } 
      //onClick   = {() => {logEvent(analytics, 'ambient_click', { project: slug, ambient: title});}} 
      onClick   = {() => { ambientClick(slug, area)}} 
    >
      <GatsbyImage 
        image = {getImage(image.childrenImageSharp[0])}
        style = 
        {{ 
          height: '80%', 
          width: '100%', 
        }} 
        alt = {alt}
      />
      
      <div
        className = "flex border-t border-solid border-black justify-center bg-sky-500 items-center"
        style = 
        {{ 
          height: '20%', 
          width: '100%', 
        }}   
      >
        <h3
          className = "m-0 p-0 flex justify-center font-bold capitalize items-center text-white text-xs 2xl:text-base xl:text-sm"

          style     = {{ fontFamily: 'Raleway'}}          
        >
          {title.length >= 15 &&
            `${title.slice(0, 15)}...`
          }

          {title.length < 15 &&
            title
          }
        </h3>   
      </div>
    </Link>
  );
}

export default Thumbnail01;