import React, { useState } from "react";
import { Link } from "gatsby";
import { Tooltip } from "antd";
import { AppstoreOutlined, HomeOutlined } from '@ant-design/icons';

function Menu02({ entornoAnalytics, isEmbedded, galleryDisabled, menuHeight, setAmbientModal, tourList }) 
{
  const [tooltipBool, setToolTipBool] = useState(true);

  const ambientClick = () =>
  {
    let entornoArray = entornoAnalytics.current.clicks;
    entornoArray.push('ambient');
    entornoAnalytics.current.clicks = entornoArray;

    setAmbientModal(true); 
    setToolTipBool(false);
  };

  return (
    <footer 
      className = {` fixed bottom-0 left-0 flex justify-center items-center bg-white text-gray-800 w-screen border-t-2 border-slate-500 drop-shadow-xl z-[1000] `}
      style = {{height : menuHeight + 'vh'}}
    >
      <div className = ' w-[350px] flex justify-center items-center h-full '>

        {!isEmbedded &&
          <Link
            className = "flex flex-1 justify-center items-center text-gray-900 hover:text-sky-600 cursor-pointer"
            to          = {'/'}
          >
            <HomeOutlined  className = " text-3xl lg:text-4xl flex "/>
          </Link>
        }

        {tourList.length > 1 &&
          <Tooltip title = {galleryDisabled ?  'CARGANDO...' : 'AMBIENTES'} trigger = "click" open = {tooltipBool}>

            <div className = "flex flex-1 justify-center items-center text-gray-900 hover:text-sky-600">
              <button
                className = {`flex flex-1 justify-center items-center ${galleryDisabled ? 'text-slate-300 animate-bounce' : 'text-gray-900 hover:text-sky-600'} cursor-pointer`}
                onClick   = {() => {ambientClick()}}
                disabled  = {galleryDisabled}
              >
                <AppstoreOutlined className = " text-3xl lg:text-4xl flex "/>
              </button>
            </div>

          </Tooltip>
          
        }
        
      </div>
    </footer>
  )
}

export default Menu02;