//Dependencies
import React from 'react';

/* NAVBAR */
const Navbar01 = (props) =>
{  
  const { data, navHeight, info } = props;

  return(
    <nav 
      className = {`select-none	fixed top-0 my-0 flex justify-center items-center bg-white text-gray-800 w-screen flex border-b border-solid border-black`}
      style = {{height : navHeight + 'vh' , zIndex: 10000}}
    >
      {data.info.available > 0 &&
        <>
          {info && info.price &&
            <h1 
              className = "2xl:text-3xl xl:text-2xl text-xl font-bold font-heading tracking-wider my-0 uppercase text-center" 
            >
              {`${data.info.name} - ${info.price}$`}
            </h1>
          }

          {data && info && !info.price &&
            <h1 
              className = "2xl:text-3xl xl:text-2xl text-xl font-bold font-heading tracking-wider my-0 uppercase text-center" 
            >
              {data.info.name}
            </h1>
          }
        </>
      }

      {data.info.available === 0 &&
        <h1 
          className = "2xl:text-3xl xl:text-2xl text-xl font-bold font-heading tracking-wider my-0 uppercase text-center" 
          >
          {`${data.info.name} - NO DISPONIBLE`}
          </h1>
      }


    </nav>
  );
}

export default Navbar01;