//Dependencies
import React from 'react';
//Icons
import {BedIcon, BathIcon, GarageIcon, PlanIcon} from '../../assets/icons/icons';

/* NAVBAR */
const Navbar02 = (props) =>
{  
  const { info, height } = props;

  if(!info.price) return null;
  //if(data.info.available === 0) return null;

  return(
    <nav 
      className = 'fixed top-0 flex justify-center items-center bg-white	text-gray-800 w-screen border-b border-solid border-black select-none'

      style = {{height : height + 'vh',  top: height + 'vh', zIndex: 10000}}     
    >
      <div 
        className = 'flex justify-evenly items-center text-gray-800 '
        style   =
        {{
          height: "100%",
          top: height,
          width: '300px'
        }}
      >
        {info.rooms && 
          <div 
            className = 'flex justify-center items-center text-gray-800 border-l border-solid border-black h-full'
            style   =
            {{
              flex: 1,
            }}
          >
            <span className = 'text-base lg:text-xl font-semibold'>
              {info.rooms}
            </span>
            &nbsp;
            <BedIcon className = 'flex text-base lg:text-xl flex font-semibold'/>
          </div>
        }

        {info.baths &&
          <div 
            className = 'flex justify-center items-center text-gray-800 border-l border-solid border-black h-full'
            style   =
            {{
              flex: 1,
            }}
          >
            <span className = 'text-base lg:text-xl font-semibold'>
              {info.baths}
            </span>
            &nbsp;
            <BathIcon className = 'flex text-base lg:text-xl flex'/>
          </div>
        }

        {info.parking &&
          <div 
          className = 'flex justify-center items-center text-gray-800 border-l border-solid border-black h-full'
          style   =
            {{
              flex: 1,
            }}
          >
            <span className = 'text-base lg:text-xl font-semibold'>
              {info.parking}
            </span>
            &nbsp;
            <GarageIcon className = 'flex text-base lg:text-xl flex'/>
          </div>
        }

        {info.area &&
          <div 
          className = 'flex justify-center items-center text-gray-800 border-x border-solid border-black h-full'
          style   =
            {{
              flex: 2,
            }}
          >
            <span className = 'text-base lg:text-xl font-semibold'>
              {info.area}
              m<sup>2</sup>
            </span>
            &nbsp;
            <PlanIcon className = 'flex text-base lg:text-xl flex'/>
          </div>
        }
      </div>
    </nav>
  );
}

export default Navbar02;